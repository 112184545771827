@import "./functions";

.question-container {
  width: rem(374);
  padding: rem(10);
  margin-inline: auto;
  margin-bottom: rem(15);
  border: 1px solid #b9b8b8;
  border-radius: rem(6);

  &.correct {
    border-color: #14a312;
  }

  .label {
    margin-left: rem(10);
  }

  input[type="checkbox"] {
    padding: rem(10);
    cursor: pointer;
  }
}
