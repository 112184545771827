@import "./functions";

.button {
  width: rem(261);
  margin-inline: auto;
  background-color: #14a312;
  color: #fff;
  display: flex;
  padding: rem(12.8);
  border-radius: rem(30);
  justify-content: center;
  font-size: rem(18);
}
