@import "./functions";

.btn {
  cursor: pointer;
  border: none;
  display: flex;
  justify-content: center;
  width: 274px;
  padding: rem(20);
  background-color: var(--primary-color);
  color: #fff;
  border-radius: rem(30);
  font-size: rem(18);
  text-decoration: none;
  margin-bottom: rem(40);
}
