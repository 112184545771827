@import "./functions";

.navigation-container {
  // background-color: aqua;
  max-width: rem(373);
  margin: rem(20) auto;
  margin-bottom: rem(40);
  display: flex;
  border-radius: rem(24);
  box-shadow: -1px 0px 10px -3px rgba(0, 0, 0, 0.75);

  .navlink {
    height: 100%;
    width: 100%;
    padding: rem(11);
    text-decoration: none;
    color: gray;
    display: flex;
    align-items: center;

    &.active {
      color: #fff;
      background-color: var(--primary-color);
      border-radius: rem(24);
    }

    svg {
      font-size: rem(14);
      margin-right: rem(4);
    }

    &__text {
      font-size: rem(14);
    }
  }
}
