@import "./functions";

.link {
  display: flex;
  align-items: center;
  color: var(--primary-color) !important;
  margin-bottom: rem(10);

  span {
    margin-inline: rem(5);
  }
}
