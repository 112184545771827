@import "./functions";

.number-container {
  display: flex;
  flex-direction: column;
  position: relative;

  &__duration {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-block: rem(5);
    gap: rem(8);

    .number {
      font-size: rem(25);
      color: var(--primary-color);
      font-weight: 900;
      margin-bottom: rem(8);
    }

    div.line {
      width: rem(30);
      height: rem(2);
      background-color: #a7a6a6;
      position: relative;
    }

    .animate-bg {
      div {
        position: absolute;
        inset: 0;
        animation: increase-width 3s;
        background-color: var(--primary-color);
      }
    }
  }
}

@keyframes increase-width {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
