@import "./functions";

.magasins-container {
  padding-inline: rem(20);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  overflow-inline: hidden;
  overflow-x: hidden;
}
