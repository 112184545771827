@import "./functions";

.images-container {
  width: rem(374);
  height: rem(300);
  margin-bottom: rem(40);
  position: relative;

  img {
    width: 100%;
    height: 100%;
    border-radius: rem(8);
  }

  .info-container {
    position: absolute;
    bottom: rem(0);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: #fff;
    width: 100%;

    &__title {
      font-size: rem(25);
    }

    &__subtitle {
      font-size: rem(16);
    }

    &__duration {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-block: rem(5);
      gap: rem(8);

      div {
        width: rem(30);
        height: rem(2);
        background-color: #a7a6a6;
        position: relative;
      }

      .animate-bg {
        div {
          position: absolute;
          inset: 0;
          animation: increase-width 3s;
          background-color: #fff;
        }
      }
    }
  }
}

@keyframes increase-width {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
