@import url("https://fonts.googleapis.com/css?family=Roboto:400,300,500,700,800,900");

:root {
  --primary-color: #303189;
  --font-roboto: Roboto, sans-serif;
  --font-Helvetica: "Helvetica Neue", sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: var(--font-roboto);
  overflow-inline: hidden;
  overflow-x: hidden;
  max-width: 100vw;
}

a,
a:visited,
a:hover {
  color: white;
  text-decoration: none;
}

.text-uppercase {
  text-transform: uppercase;
}

.bg-white {
  background-color: #fff;
}

.text-white {
  color: #fff;
}
