@import "./functions";

.marques-container {
  padding: rem(20);

  .imgs-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: rem(25);
    align-items: center;
    justify-content: center;
    width: fit-content;
    margin: 0 auto;

    .img-container {
      display: flex;
      align-items: center;
      height: 73.09px;
      width: 180px;

      img {
        object-fit: fill;
        width: 100%;
        height: 100%;
      }
    }
  }

  &__duration {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block: rem(20);
    gap: rem(8);

    div {
      width: rem(30);
      height: rem(2);
      background-color: #a7a6a6;
      position: relative;
    }
    .animate-bg {
      div {
        position: absolute;
        inset: 0;
        animation: increase-width 5s;
        background-color: var(--primary-color);
      }
    }
  }
}

@keyframes increase-width {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}
