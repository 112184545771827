.goodanswer-container {
  padding: 16px;
  margin-bottom: 20px;
  background-color: #f3efef;

  h4 {
    font-size: 18px;
    margin-bottom: 5px;
    color: #14a312;
  }

  p {
    color: #797676;
  }
}
