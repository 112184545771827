@import "./functions";

.navbar-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  margin-bottom: rem(40);

  .icon-container {
    width: rem(48);
    height: rem(48);
    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      fill: var(--primary-color);
    }
  }

  .logo {
    font-family: var(--font-Helvetica);
    text-transform: uppercase;
    color: gray;
    font-size: rem(30);
    font-weight: 400; // Normal
  }

  .right {
    display: flex;

    .search-icon {
      transform: rotate(90deg);
    }
  }
}
