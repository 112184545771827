@import "./functions";

.questionnaire-container {
  padding: rem(20);

  .numbers-container {
    display: flex;
    justify-content: center;
  }
}
