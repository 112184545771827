@import "./functions";

.slider-scaling-container {
  margin-bottom: rem(40);
  display: flex;
  gap: rem(25);

  .slider-wrapper {
    width: rem(196);
    height: rem(260);
    position: relative;
    border-radius: rem(5);

    &.last {
      transform: translate3d(-442px, 0, 0);
    }

    &.others {
      transform: translate3d(221px, 0, 0);
    }

    &.prec {
      // animation: transition-prec 1s ease-in-out;
      z-index: -1;
      order: 3;
    }

    &.active {
      // animation: transition-next 1s ease-in-out;
      transform: scale(1.2);
      order: 2;
    }

    &.next {
      // animation: transition-next 1s ease-in-out;
      order: 1;
    }

    .slider-content {
      position: absolute;
      bottom: 0;
      left: 0;
      color: #fff;
      padding-left: rem(10);
      padding-bottom: rem(10);

      h1 {
        font-size: rem(22);
      }

      p {
        font-size: rem(14);
      }
    }
  }
}

@keyframes transition-next {
  100% {
    transform: translateX(rem(221));
    z-index: 1;
  }
}
@keyframes transition-prec {
  100% {
    transform: translateX(-rem(442));
  }
}
